<template>
    <Head :title="$t('credit-payment.create_credit_payment') + ' - ' + order.extern_id" />
    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('credit-payment.create_credit_payment') }} - {{ order.extern_id }}</h1>
        </template>

        <form
            method="POST"
            class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg"
            @submit.prevent="onSubmit"
        >
            <div class="grid grid-cols-6 gap-8">
                <!-- Order Info -->
                <div class="col-span-3">
                    <div class="py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="sm:py-5">
                                <h3 class="text-lg font-semibold">{{ $t('credit-payment.order_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.id') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ order.extern_id }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_no') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ order.number }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_subtotal') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ formatPrice(order.order_subtotal / 100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_shipment') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ formatPrice((order.shipping_subtotal + order.shipping_tax) / 100) }}
                                    <small>({{ $t('credit-payment.inc') }} {{ formatPrice(order.shipping_tax/100) }} {{ $t('credit-payment.vat') }})</small>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.total_amount') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ formatPrice(order.total / 100) }}
                                    <small>({{ $t('credit-payment.inc') }} {{ formatPrice(order.order_tax/100) }} {{ $t('credit-payment.vat') }})</small>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <!-- Customer Info -->
                <div class="col-span-3">
                    <div class="py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="sm:py-5">
                                <h3 class="text-lg font-semibold">{{ $t('credit-payment.customer_information') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.customer') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    <div v-if="!order.customer">{{ $t('credit-payment.guest') }}</div>
                                    <div v-else class="text-sm font-medium text-gray-900">
                                        <div>
                                            {{ order.customer.firstname }}
                                            {{ order.customer.lastname }}
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            <div>
                                                {{ order.customer.email }}
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.invoice_address') }}</dt>
                                <dd class="mt-1 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        <div v-if="order.billing_company_name" class="font-bold">
                                            {{ order.billing_company_name }}
                                        </div>
                                        <div>
                                            {{ order.billing_firstname }}
                                            {{ order.billing_lastname }}
                                        </div>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.billing_address }}
                                        </div>
                                        <div>
                                            {{ order.billing_postcode }}
                                            {{ order.billing_city }}
                                            {{ order.billing_country }}
                                        </div>
                                    </div>
                                    <div v-if="order.email" class="mt-2 text-sm font-medium text-gray-900">
                                        {{ order.email }}
                                    </div>
                                    <div v-if="order.billing_address.phone" class="text-sm text-gray-500">
                                        {{ order.billing_address.phone }}
                                    </div>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.delivery_address') }}</dt>
                                <dd class="mt-1 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        <div v-if="order.shipping_company_name" class="font-bold">
                                            {{ order.shipping_company_name }}
                                        </div>
                                        <div>
                                            {{ order.shipping_firstname }}
                                            {{ order.shipping_lastname }}
                                        </div>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.shipping_address }}
                                        </div>
                                        <div>
                                            {{ order.shipping_postcode }}
                                            {{ order.shipping_city }}
                                            {{ order.shipping_country }}
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <hr class="col-span-6" />

                <!-- Items -->
                <div class="col-span-6">
                    <h3 class="text-xl font-semibold mb-4">{{ $t('credit-payment.items') }}</h3>

                    <table class="w-full border-collapse">
                        <thead class="border-b">
                        <tr>
                            <th class="text-left py-2 align-middle">{{ $t('credit-payment.item') }}</th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.qty') }}</th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.subtotal') }}</th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.vat') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, iIndex) in localOrder.items" :key="iIndex" class="border-b">
                            <td class="py-2 pr-4 w-[500px] align-middle">
                                {{ item.name }}
                                <button
                                    type="button"
                                    class="ml-2 px-2 py-1 text-sm font-medium text-indigo-600 bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:ring focus:ring-indigo-300"
                                    @click="setItemToMax(item)"
                                >
                                    {{ $t('credit-payment.all') }}
                                </button>
                            </td>
                            <td class="py-2 w-60 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ item.quantity }}x</span>
                                    <input
                                        type="number"
                                        v-model="item.credit_quantity"
                                        class="w-20 text-center rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                                        :min="0"
                                        :max="item.quantity"
                                        placeholder="0"
                                        @input="updateItemCredits(item)"
                                    />
                                    <ValidationError :name="`items.${iIndex}.credit_quantity`" />
                                </div>
                            </td>
                            <td class="py-2 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ formatPrice(item.total / 100) }}</span>
                                    <MoneyInput v-model="item.credit_subtotal" class="w-40 text-right" />
                                    <ValidationError :name="`items.${iIndex}.credit_subtotal`" />
                                </div>
                            </td>
                            <td class="py-2 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ formatPrice(item.total_tax / 100) }}</span>
                                    <MoneyInput v-model="item.credit_tax" class="w-40 text-right" />
                                    <ValidationError :name="`items.${iIndex}.credit_tax`" />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Fees -->
                <div class="col-span-6" v-if="localOrder.fees.length > 0">
                    <h3 class="text-xl font-semibold mb-4">{{ $t('credit-payment.additional_fees') }}</h3>
                    <table class="w-full border-collapse">
                        <thead class="border-b">
                        <tr>
                            <th class="text-left py-2 align-middle">{{ $t('credit-payment.addition') }}</th>
                            <th class="text-left py-2 align-middle"></th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.subtotal') }}</th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.vat') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(fee, fIndex) in localOrder.fees" :key="fIndex" class="border-b">
                            <td class="py-2 pr-4 w-[500px] align-middle">
                                {{ fee.name }}
                                <button
                                    type="button"
                                    class="ml-2 px-2 py-1 text-sm font-medium text-indigo-600 bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:ring focus:ring-indigo-300"
                                    @click="setFeeToMax(fee)"
                                >
                                    {{ $t('credit-payment.all') }}
                                </button>
                            </td>
                            <td class="py-2 w-60 text-right align-middle"></td>
                            <td class="py-2 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ formatPrice(fee.subtotal / 100) }}</span>
                                    <MoneyInput v-model="fee.credit_subtotal" class="w-40 text-right" />
                                    <ValidationError :name="`fees.${fIndex}.credit_subtotal`" />
                                </div>
                            </td>
                            <td class="py-2 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ formatPrice(fee.tax / 100) }}</span>
                                    <MoneyInput v-model="fee.credit_tax" class="w-40 text-right" />
                                    <ValidationError :name="`fees.${fIndex}.credit_tax`" />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Shipping -->
                <div class="col-span-6">
                    <h3 class="text-xl font-semibold mb-4">{{ $t('credit-payment.shipping') }}</h3>
                    <table class="w-full border-collapse">
                        <thead class="border-b">
                        <tr>
                            <th class="text-left py-2 align-middle">{{ $t('credit-payment.shipping_method') }}</th>
                            <th class="text-left py-2 align-middle"></th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.subtotal') }}</th>
                            <th class="text-right py-2 align-middle">{{ $t('credit-payment.vat') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="border-b">
                            <td class="py-2 w-[500px] pr-4 align-middle">
                                {{ localOrder.shipping_method.name }}
                                <button
                                    type="button"
                                    class="ml-2 px-2 py-1 text-sm font-medium text-indigo-600 bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:ring focus:ring-indigo-300"
                                    @click="setShippingToMax"
                                >
                                    {{ $t('credit-payment.all') }}
                                </button>
                            </td>
                            <td class="py-2 w-60 text-right align-middle"></td>
                            <td class="py-2 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ formatPrice(localOrder.shipping_subtotal / 100) }}</span>
                                    <MoneyInput v-model="localOrder.credit_shipping_subtotal" class="w-40 text-right" />
                                    <ValidationError name="credit_shipping_subtotal" />
                                </div>
                            </td>
                            <td class="py-2 text-right align-middle">
                                <div class="flex flex-col items-end">
                                    <span class="py-1 text-sm text-gray-500">{{ formatPrice(localOrder.shipping_tax / 100) }}</span>
                                    <MoneyInput v-model="localOrder.credit_shipping_tax" class="w-40 text-right" />
                                    <ValidationError name="credit_shipping_tax" />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>


                <div class="col-span-6">
                    <Label for="reason_id" v-bind:value="$t('credit-payment.reason')" />

                    <select id="reason_id" v-model="form.reason_id" class="mt-1 block w-full rounded mb-4" required>
                        <option value="" disabled>{{ $t('credit-payment.select_reason') }}</option>
                        <option v-for="item in reasons" :key="item.id" :value="item.id">
                            {{ $t('credit-payment.' + item.name) }}
                        </option>
                    </select>
                    <ValidationError name="reason_id" />

                    <template v-if="form.reason_id === 5">
                        <Label for="reason" :value="$t('credit-payment.reason_other_placeholder')" />
                        <Textarea id="reason" v-model="form.reason" class="mt-1 block w-full" />
                        <ValidationError name="reason" />
                    </template>
                </div>

                <div class="col-span-6">
                    <label for="iban" class="block font-medium text-sm text-gray-700">{{ $t('credit-payment.iban_customer') }}</label>
                    <input
                        id="iban"
                        v-model="form.iban"
                        type="text"
                        class="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring-indigo-200 focus:ring-opacity-50"
                        placeholder="NL00 KNAB 0000 0000 00"
                    />
                    <ValidationError name="iban" />
                </div>

                <hr class="col-span-6" />

                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('orders.show', { order: order.id })">{{ $t('Back') }}</NavButton>
                    <Button type="submit" :disabled="form.processing">{{ $t('Save') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script setup>
import { reactive, watch } from 'vue';
import { Head, useForm } from '@inertiajs/vue3'
import AuthenticatedLayout from "@/Layouts/Authenticated.vue";
import Button from '@/Components/Button.vue';
import Label from '@/Components/Label.vue';
import MoneyInput from '@/Components/MoneyInput.vue';
import NavButton from '@/Components/NavButton.vue';
import Textarea from '@/Components/Textarea.vue';
import ValidationError from '@/Components/ValidationError.vue';

const props = defineProps({
    order: Object,
    reasons: Array,
    finalProductsForShipment: Object,
    otherReasonId: Number
});

const form = useForm({
    reason_id: '',
    reason: '',
    requires_accounting_sync: false,
    items: [],
    fees: [],
    credit_shipping_subtotal: null,
    credit_shipping_tax: null,
    iban: '',
});

const localOrder = reactive({
    items: props.order.items.map(item => ({
        ...item,
        credit_quantity: item.credit_quantity || 0,
        credit_subtotal: item.credit_subtotal || 0,
        credit_tax: item.credit_tax || 0,
    })),
    fees: props.order.fees.map(fee => ({
        ...fee,
        credit_subtotal: fee.credit_subtotal || 0,
        credit_tax: fee.credit_tax || 0,
    })),

    credit_shipping_subtotal: props.order.credit_shipping_subtotal || 0,
    credit_shipping_tax: props.order.credit_shipping_tax || 0,
    shipping_method: props.order.shipping_method,
    shipping_subtotal: props.order.shipping_subtotal,
    shipping_tax: props.order.shipping_tax,
});

watch(() => form.reason_id, (newValue) => {
    if (newValue !== 5) {
        form.reason = '';
    }
});

function updateItemCredits(item) {
    const creditQty = Number(item.credit_quantity) || 0;
    item.credit_subtotal = ((item.total / item.quantity) * creditQty) / 100;
    item.credit_tax = ((item.total_tax / item.quantity) * creditQty) / 100;
}

function setItemToMax(item) {
    item.credit_quantity = item.quantity;
    item.credit_subtotal = item.total / 100;
    item.credit_tax = item.total_tax / 100;
    updateItemCredits(item);
}

function setShippingToMax() {
    localOrder.credit_shipping_subtotal = localOrder.shipping_subtotal / 100;
    localOrder.credit_shipping_tax = localOrder.shipping_tax / 100;
}

function setFeeToMax(fee) {
    fee.credit_subtotal = fee.subtotal / 100;
    fee.credit_tax = fee.tax / 100;
}

function onSubmit() {
    form.items = localOrder.items.map(item => ({
        id: item.id,
        credit_quantity: item.credit_quantity,
        credit_subtotal: item.credit_subtotal,
        credit_tax: item.credit_tax,
    }));

    form.fees = localOrder.fees.map(fee => ({
        id: fee.id,
        credit_subtotal: fee.credit_subtotal,
        credit_tax: fee.credit_tax,
    }));

    form.credit_shipping_subtotal = localOrder.credit_shipping_subtotal;
    form.credit_shipping_tax = localOrder.credit_shipping_tax;

    form.post(route('credit-payment.store', { order: props.order.id }), { preserveScroll: true });
}
</script>

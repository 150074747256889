<template>
    <Head :title="indexTitle" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">
                    {{ indexTitle }}
                </h1>
                <div v-if="can('view all orders')">
                    <div class="flex rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input id="search" v-model="searchQuery" type="text" name="search" class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Zoeken..." @keyup.enter="search" />
                        </div>
                        <button type="button" class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500" @click="search">Zoeken</button>
                    </div>
                </div>
            </div>
        </template>

        <pagination class="mb-2" :links="orders" />

        <div class="flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full py-2 align-middle">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="divide-y divide-gray-200 w-full">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.id') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.order_date') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.invoice') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.deliver') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.contact') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.shipments') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.total_amount') }}</th>
                                <th scope="col" class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">{{ $t('orders.status') }}</th>
                                <th scope="col" class="relative px-4 py-2">
                                    <span class="sr-only">{{ $t('orders.view') }}</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="order in orders.data" :key="order.id" :class="order.tickets.length > 0 ? 'bg-yellow-100' : ''">
                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                    {{ order.number }}
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div class="text-xs font-medium text-gray-900">
                                        {{ dayjs(order.ordered_at).format('LL') }}
                                    </div>
                                    <div class="text-xs text-gray-500">
                                        {{ dayjs(order.ordered_at).format('LT') }}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div class="text-xs font-medium text-gray-900">
                                        <div v-if="order.billing_company_name">
                                            {{ order.billing_company_name }}
                                        </div>
                                        <div>
                                            {{ order.billing_firstname }}
                                            {{ order.billing_lastname}}
                                        </div>
                                    </div>
                                    <div class="text-xs text-gray-500">
                                        <div>
                                            {{ order.billing_address }}
                                        </div>
                                        <div>
                                            {{ order.billing_postcode }}
                                            {{ order.billing_city }}
                                            {{ order.billing_country }}
                                        </div>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div class="text-xs font-medium text-gray-900">
                                        <div v-if="order.shipping_company_name">
                                            {{ order.shipping_company_name }}
                                        </div>
                                        <div>
                                            {{ order.shipping_firstname }}
                                            {{ order.shipping_lastname}}
                                        </div>
                                    </div>
                                    <div class="text-xs text-gray-500">
                                        <div>
                                            {{ order.shipping_address }}
                                        </div>
                                        <div>
                                            {{ order.shipping_postcode }}
                                            {{ order.shipping_city }}
                                            {{ order.shipping_country }}
                                        </div>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div v-if="order.email" class="text-xs font-medium text-gray-900">
                                        {{ order.email }}
                                    </div>
                                    <div v-if="order.phone" class="text-xs text-gray-500">
                                        {{ order.phone }}
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2">
                                    <div v-for="shipment in order.shipments" :key="shipment.id">
                                        <a target="_blank" :href="shipment.tracking_url">{{ shipment.barcode }}</a>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                    <div class="text-xs font-medium text-gray-900">
                                        <span v-if="order.credit_payment">
                                            <s>{{ formatPrice(order.total/100) }}</s>
                                            {{ formatPrice(order.credit_payment.remaining_total/100) }}
                                        </span>
                                        <span v-else>
                                            {{ formatPrice(order.total/100) }}
                                        </span>
                                    </div>
                                </td>

                                <td class="whitespace-nowrap px-4 py-2">
                                    <button v-if="can('update status order')" class="block text-right text-indigo-600 hover:text-indigo-900" @click="updateOrderStatus = order">
                                        <status-badge :status="order.status"></status-badge>
                                    </button>
                                </td>
                                <td class="flex flex-col space-y-2 whitespace-nowrap px-4 py-2 text-right text-xs font-medium">
                                    <Link
                                        v-if="can('pack order') && order.status == 'processing' && order.packed_items_count == 0 "
                                        :href="
                                                route('orders.pack', {
                                                    order: order.id
                                                })
                                            "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >
                                        {{ $t('orders.pack') }}
                                    </Link>
                                    <Link
                                        v-if="can('create retour') && order.packed_items_count >= 1"
                                        :href="
                                                route('orders.pack', {
                                                    order: order.id
                                                })
                                            "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >
                                        {{ $t('orders.add_return') }}
                                    </Link>
                                    <Link
                                        v-if="can('view order') || can('view limited completed orders')"
                                        :href="
                                                route('orders.show', {
                                                    order: order.id
                                                })
                                            "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >
                                        {{ $t('orders.details') }}
                                    </Link>

                                    <Link
                                        v-if="can('pack order') && order.status == 'processing' || order.status == 'for inspection'"
                                        :href="
                                                route('pack.pack', {
                                                    order: order.id
                                                })
                                            "
                                        class="text-indigo-600 hover:text-indigo-900"
                                    >


                                        {{ $t('orders.pack') }}
                                    </Link>

                                    <button v-if="can('update status order')" class="block text-right text-indigo-600 hover:text-indigo-900" @click="updateOrderStatus = order">{{ $t('orders.change_status') }}</button>
                                </td>
                            </tr>
                            <tr v-if="orders.data.length == 0">
                                <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900" colspan="8">{{ $t('orders.no_results_found') }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <pagination class="mt-2" :links="orders" />

        <update-status-modal v-if="can('update status order')" :order="updateOrderStatus" @close="updateOrderStatus = false" />
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link } from '@inertiajs/vue3'
import Pagination from '@/Components/Pagination.vue'
import UpdateStatusModal from '@/Pages/Orders/UpdateStatusModal.vue'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        UpdateStatusModal,
        Pagination,
        AuthenticatedLayout,
        Head,
        Link
    },

    props: {
        orders: Object,
        indexTitle: String,
        oldSearchQuery: String,
        currentType: String
    },
    data() {
        return {
            updateOrderStatus: false,
            searchQuery: this.oldSearchQuery
        }
    },

    methods: {
        search() {
            this.$inertia.get(
                route(route().current()),
                { query: this.searchQuery},
                {
                    preserveState: true
                }
            )
        }
    }
}
</script>

<template>
    <div>
        <div class="relative rounded-md shadow-sm">

            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm">€</span>
            </div>

            <input
                ref="inputRef"
                type="text"
                class="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-300
               focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                placeholder="0.00"
                aria-describedby="price-currency"
            />

            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span id="price-currency" className="text-gray-500 sm:text-sm">EUR</span>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'

export default {
    name: 'MoneyInput',
    props: {
        modelValue: {
            type: Number,
            default: 0
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const { inputRef, value, setValue } = useCurrencyInput({
            currency: 'EUR',
            locale: 'nl-NL',
            currencyDisplay: 'hidden',
            precision: { min: 2, max: 2 },
            autoDecimalMode: true
        })

        onMounted(() => {
            setValue(props.modelValue)
        })

        watch(value, (newVal) => {
            if (newVal !== props.modelValue) {
                emit('update:modelValue', newVal)
            }
        })

        watch(() => props.modelValue, (newVal) => {
            // Always call setValue when prop changes
            setValue(newVal)
        })

        return { inputRef }
    }
}
</script>

<template>
    <Head :title="indexTitle" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">
                    {{ indexTitle }}
                </h1>
            </div>
        </template>

        <div class="flex flex-col">
            <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                    <div class="overflow-hidden sm:rounded-lg">
                        <form @submit.prevent="checkPaste">
                            <textarea
                                v-model="form.content"
                                class="w-full p-3 border border-gray-300 rounded-md"
                                rows="20"
                                :placeholder="$t('orders.noted_add')">
                            </textarea>
                            <Button
                                class="focus:shadow-outline-gray inline-flex items-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-xs font-semibold uppercase tracking-widest text-white transition duration-150 ease-in-out hover:bg-gray-700 focus:border-gray-900 focus:outline-none active:bg-gray-900"
                                type="submit">
                                {{ $t('transactions.submit') }}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmationModal :show="confirmationModalVisible" @close="confirmationModalVisible = false">
            <template #icon>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full" :class="responseData.errors?.length ? 'bg-red-100' : 'bg-green-100'">
                    <component :is="responseData.errors?.length ? 'ExclamationTriangleIcon' : 'DocumentCheckIcon'" class="h-6 w-6" :class="responseData.errors?.length ? 'text-red-600' : 'text-green-600'" aria-hidden="true" />
                </div>
            </template>

            <template #content>
                <div>
                    <div v-if="responseData.errors?.length">
                        <h3 class="text-red-600 font-bold py-2">{{ $t('transactions.errors') }}</h3>
                        <div class="border border-red-600 rounded-md bg-red-100 p-4 mb-4">
                            <ul>
                                <li v-for="error in responseData.errors" :key="error" class="text-red-800">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="!responseData.errors?.length">
                        <div class="overflow-auto">
                            <h3 class="py-2 text-lg font-semibold">{{ $t('transactions.approved_transactions') }}</h3>

                            <table class="min-w-full border border-gray-300">
                                <thead class="bg-gray-100">
                                <tr>
                                    <th class="border px-4 py-2 text-center">{{ $t('transactions.description') }}</th>
                                    <th class="border px-4 py-2 text-center">{{ $t('transactions.amount') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!responseData.approved_transactions?.length">
                                        <td class="border px-4 py-2 text-center" colspan="2">
                                            {{ $t('transactions.no_approved_transactions_found') }}
                                        </td>
                                    </tr>
                                    <tr v-else v-for="transaction in responseData.approved_transactions" :key="transaction.id">
                                        <td class="border px-4 py-2 text-center">{{ transaction.id }}</td>
                                        <td class="border px-4 py-2 text-center">{{ formatPrice(transaction.amount / 100) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="overflow-auto">
                            <h3 class="py-2 text-lg font-semibold">{{ $t('transactions.denied_transactions') }}</h3>

                            <table class="min-w-full border border-gray-300">
                                <thead class="bg-gray-100">
                                    <tr>
                                        <th class="border px-4 py-2 text-center">{{ $t('transactions.description') }}</th>
                                        <th class="border px-4 py-2 text-center">{{ $t('transactions.amount') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="!responseData.rejected_transactions?.length">
                                        <td class="border px-4 py-2 text-center" colspan="2">
                                            {{ $t('transactions.no_denied_transactions_found') }}
                                        </td>
                                    </tr>
                                    <tr v-else v-for="transaction in responseData.rejected_transactions" :key="transaction.id">
                                        <td class="border px-4 py-2 text-center">{{ transaction.id }}</td>
                                        <td class="border px-4 py-2 text-center">{{ formatPrice(transaction.amount / 100) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" :disabled="responseData.errors?.length" @click="confirmSubmit">{{ $t('transactions.confirm') }}</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmationModalVisible = false">{{ $t('transactions.cancel') }}</button>
            </template>
        </ConfirmationModal>

    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue';
import { Head } from '@inertiajs/vue3';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { ExclamationTriangleIcon, DocumentCheckIcon } from '@heroicons/vue/24/outline';

export default {
    components: {
        ConfirmationModal,
        AuthenticatedLayout,
        Head,
        ExclamationTriangleIcon,
        DocumentCheckIcon,
    },
    props: {
        indexTitle: String,
    },
    data() {
        return {
            confirmationModalVisible: false,
            form: this.$inertia.form({
                content: '',
            }),
            responseData: {
                approved_transactions: [],
                denied_transactions: [],
                errors: [],
            },
        };
    },
    methods: {
        async checkPaste() {
            if (this.form.content.trim() === '') {
                alert('Content cannot be empty.');
                return;
            }
            try {
                const response = await axios.post(this.route('transactions.checkPaste'), this.form);
                this.responseData = response.data;
                this.confirmationModalVisible = true;
            } catch (error) {
                console.error('Error submitting paste:', error);
            }
        },
        confirmSubmit() {
            this.form.post(this.route('transactions.processPaste'), {
                onSuccess: () => {
                    this.confirmationModalVisible = false;
                    this.form.reset();
                },
            });
        },
    },
};
</script>

<template>
    <Head :title="'Gebruiker - ' + user.name" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex flex-wrap items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">Gebruiker - {{ user.name }}</h1>
                <div class="space-x-2">
                    <NavButton v-if="can('view user')" :href="route('users.index')">Terug</NavButton>
                    <NavButton v-if="can('edit user')" :href="route('users.edit', { user: user.id })">Wijzigen</NavButton>
                    <Button v-if="can('delete user')" @click="confirmingUserDeletion = true">Verwijderen</Button>
                </div>
            </div>
        </template>

        <div class="overflow-hidden bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Naam</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {{ user.name }}
                        </dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">E-mailadres</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {{ user.email }}
                        </dd>
                    </div>
                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Rol</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <span class="inline-flex rounded-full bg-indigo-100 px-2 text-xs font-semibold leading-5 text-indigo-800">
                                {{ user.role }}
                            </span>
                        </dd>
                    </div>

                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">PakBon/Label printen naar labelprinter</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <status-badge :status="user.print_to_label_printer ? 'active' : 'inactive'"></status-badge>
                        </dd>
                    </div>

                    <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt class="text-sm font-medium text-gray-500">Status</dt>
                        <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <status-badge :status="user.status ? 'active' : 'inactive'"></status-badge>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
        <ConfirmationModal v-if="can('edit user')" :show="confirmingUserDeletion" @close="confirmingUserDeletion = false">
            <template #title>Gebruiker verwijderen - {{ user.name }}</template>

            <template #content>Weet je zeker dat je deze gebruiker wilt verwijderen?</template>

            <template #footer>
                <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm" @click="deleteUser">Gebruiker verwijderen</button>
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm" @click="confirmingUserDeletion = false">Annuleren</button>
            </template>
        </ConfirmationModal>
    </AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head } from '@inertiajs/vue3'
import Button from '@/Components/Button.vue'
import NavButton from '@/Components/NavButton.vue'
import ConfirmationModal from '@/Components/ConfirmationModal.vue'
import StatusBadge from '@/Components/StatusBadge.vue'

export default {
    components: {
        StatusBadge,
        ConfirmationModal,
        NavButton,
        Button,
        AuthenticatedLayout,
        Head
    },
    props: {
        user: Object
    },

    data() {
        return {
            confirmingUserDeletion: false,
            form: this.$inertia.form({})
        }
    },
    methods: {
        deleteUser() {
            this.form.delete(
                route('users.destroy', {
                    user: this.user.id
                })
            )
        }
    }
}
</script>
